import { IHeadboardAniversarioProps } from '../headboardAniversario';

function headboardAniversarioAdapter(data: any): IHeadboardAniversarioProps {
    return {
        title: data.tituloPrincipal,
        image: data.imagenHeader.file.url,
        bgImage: data.imagenesArboles ? data.imagenesArboles[0].file.url : '',
        youtubeId: data.redireccionBoton
    };
}

export default headboardAniversarioAdapter;
