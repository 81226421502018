import React, { Fragment, useState } from 'react'
import './footer.scss'
import { useStaticQuery, graphql } from "gatsby";
import Follows from '../follows/follows';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords, findTarget, findRel } from '../../../shared/utils/stringUtilities';
import Button from '../../atoms/button/button';

const Footer = (props: { isSuperFooter: boolean, footerImg?: string, footerWhite?: boolean, footerBlack?: boolean, footerDientelandia?: boolean}) => {

   const data = useStaticQuery(graphql`
      query FOOTER_CONTENT {
         allContentfulHome(filter: {titulo: {eq: "Home (feature_153471) NO TOCAR"}}) {
            nodes {
               textCentralLlamadas
               redireccionTitulo
            }
         }
         allContentfulFooter(filter: {titulo: {eq: "Footer comfama.com"}}) {
            nodes {
               titulo
               categorias {
                  nombreCategoria
                  noVisibleFooter
                  hyperLink
                  referenciaSubcategorias {
                     titulo
                     slug
                     link
                     nombreSubcategoria
                     tipoCampo
                     appStore {
                        title
                        file {
                           url
                        }
                     }
                     playStore {
                        title
                        file {
                           url
                        }
                     }
                  }
               }
            }
         }
         allContentfulMenuLoggin(filter: {titulo: {eq: "Menu login comfama.com"}}) {
            nodes {
               logoGray {
                  title
                  file {
                     url
                  }
               }
            }
         }
         allContentfulFooterSeccion2(filter: {titulo: {eq: "Footer comfama.com"}}) {
            nodes {
               tituloPrincipal
               descripcion {
                  json
               }
               lgoVigiladosVertical {
                  title
                  file {
                     url
                  }
               }
               logoAsojacas {
                  title
                  file {
                     url
                  }
               }
               logoRedPactoGlobal {
                  title
                  file {
                     url
                  }
               }
            }
         }
      }
   `)

   interface StoreInterface {
      link?:string;
      title: string;
      file: {
         url: string
      };
   }

   interface FooterInterface {
      nombreCategoria: string;
      noVisibleFooter: boolean;
      hyperLink: string;
      referenciaSubcategorias: {
         titulo:string;
         link: string;
         slug:string;
         nombreSubcategoria: string;
         tipoCampo: string;
         appStore: StoreInterface;
         playStore: StoreInterface;
      }[];
   }

   interface Footer2Interface {
      tituloPrincipal: string;
      descripcion: {
         json: any
      };
      lgoVigiladosVertical:StoreInterface;
      logoAsojacas:StoreInterface;
      logoRedPactoGlobal:StoreInterface;
   }

   const titleCallCenter: string = data.allContentfulHome.nodes[0].textCentralLlamadas;
   const linkTitleCallCenter: string = data.allContentfulHome.nodes[0].redireccionTitulo;
   const queryFooter:FooterInterface[] = data.allContentfulFooter.nodes[0].categorias;
   const queryFooter2:Footer2Interface = data.allContentfulFooterSeccion2.nodes[0];
   const logoGray = data.allContentfulMenuLoggin.nodes[0].logoGray;

   const [selectIndex, setSelectIndex] = useState(-1);

   const toggleButton = (index: number) => {
      setSelectIndex( selectIndex === index ? -1 : index );
   }

   const getNormalItems = ( items :FooterInterface[]) => (
      items.map( (val:FooterInterface , key: number ) => (
         !val.noVisibleFooter ?
         <div className='f-container__one' key={key} >
            <span 
               className={`f-container__one__title mode-mobile ${key === selectIndex ? 'mode-mobile__show' : '' }`}
               onClick={ () => toggleButton(key) } 
            >{val.nombreCategoria}</span>
            <ul className='f-container__one__list' >
               {val.referenciaSubcategorias.map( (subVal, key) => {
                  const gtm = `gtm${normalizeTwoWords(val.nombreCategoria)}${normalizeTwoWords(subVal.nombreSubcategoria)}`;
                  const ref = (subVal.slug && `/${subVal.slug}`) || subVal.link;
                  return(
                     <li className={`f-container__one__list__li ${gtm}`} key={key} >
                        <a 
                           className={gtm}
                           title={subVal.nombreSubcategoria}
                           href={ref} 
                           target={findTarget(ref) }
                           rel={ findRel(ref) }
                        >{subVal.nombreSubcategoria}</a>
                     </li>
                  )
               } )}
            </ul>
         </div>
         : <></>
      ) )
   )

   const getCallCenterItems = ( items:FooterInterface[], title:string|null, classP:string, classTitle:string ) => (
      <div className={`f-container__one ${classP}`} >
         {title && linkTitleCallCenter ? 
            <Button
               isDisabled={true}
               type={'link'}
               text={title}
               strictCss={true}
               gtmMark={`gtm${normalizeTwoWords(title)}`}
               href={linkTitleCallCenter}
               classname='f-container__one__title'  
            /> 
            : <span className='f-container__one__title'  >{title}</span>
         }
         {items.map( (val, key) => ( 
            !val.noVisibleFooter ?
               <Fragment key={key}>
                  { val.hyperLink ? 
                     <a className={`f-container__one__title ${classTitle}`}
                        href={ val.hyperLink }  
                        target={ findTarget(val.hyperLink) } 
                        rel={val.hyperLink} 
                     >
                        {val.nombreCategoria}
                     </a> : 
                     <span className={`f-container__one__title ${classTitle}`}  >{val.nombreCategoria}</span>
                  }
                  <ul className='f-container__one__list'>
                     {val.referenciaSubcategorias.map( (subVal, key) => (
                        <li className='f-container__one__list__li' key={key} >
                           <a className={`${val.nombreCategoria === 'Ubicación' ? 'footer-address' : ''}`}
                              rel="nofollow noreferrer" 
                              title={subVal.nombreSubcategoria} 
                              href={`tel:${subVal.nombreSubcategoria}`} 
                           >
                              {val.nombreCategoria === 'Ubicación' ? <i className='icon-home' /> : <i className='icon-phone' />}
                              {subVal.nombreSubcategoria}
                           </a>
                        </li>
                     ) )}
                  </ul>
               </Fragment>
            : <></>
         ))}
      </div>
   )

   const getCulturaComfamaItems = ( items:FooterInterface[] ) => (
      items.map( (val:FooterInterface , key: number ) => (
         !val.noVisibleFooter ?
            <div className='f-container__one' key={key} >
               <span className='f-container__one__title call'  >{val.nombreCategoria}</span>
               <ul className='f-container__one__list' >
                  {val.referenciaSubcategorias.map( (subVal, key) => (
                     <Fragment key={key} >
                        <li className='f-container__one__list__li' >{subVal.nombreSubcategoria}</li>
                        <li  className='f-container__one__list__imgs' >
                           <a className={`gtmHome${normalizeTwoWords(val.nombreCategoria)}AppStore`}  title={subVal.appStore.title} href={subVal.appStore.link || '/'} target='_blank' rel="noreferrer" >
                              <img loading="lazy" src={(subVal.appStore) && subVal.appStore.file.url} alt={subVal.appStore.title} ></img>
                           </a>
                           <a className={`gtmHome${normalizeTwoWords(val.nombreCategoria)}GooglePlay`}  title={subVal.playStore.title} href={subVal.appStore.link || '/'} target='_blank' rel="noreferrer" >
                              <img loading="lazy" src={(subVal.playStore) && subVal.playStore.file.url} alt={subVal.playStore.title} ></img>
                           </a>
                        </li>
                     </Fragment>
                  ) )}
               </ul>
            </div> 
         : <></>
      ))
   )
   
   return (
      <footer className={`m-footer ${props.footerWhite ? 'footer--white' : ''} ${props.footerBlack ? 'footer--black' : ''} ${props.footerDientelandia ? 'footer--dientelandia' : ''}`}>
         { props.footerImg
            ? <img loading='lazy' className='m-footer__bg' src={props.footerImg} alt="" />
            : <></>
         }
         <div className='f-container' >
            {getNormalItems(queryFooter.slice(0, 4))}
            {getCallCenterItems(queryFooter.slice(4, 7), titleCallCenter, 'call', 'title_sub')}
            {getCallCenterItems(queryFooter.slice(7, 9), titleCallCenter, 'call call-hide', 'title_sub')}
            {getCulturaComfamaItems(queryFooter.slice(9, 10))}
            <div className='f-container__one call'>
               <Follows query={queryFooter[queryFooter.length - 1]} />
            </div>
         </div>
         <div className='f-final-content' >
            <div className='f-final-content__box' >
               <span className='f-final-content__box__title'  >{queryFooter2.tituloPrincipal}</span>
               <div className='f-final-content__box__imgs' >
                  <img
                     loading="lazy"
                     className='f-final-content__box__imgs__img'
                     src={(queryFooter2.logoAsojacas) && queryFooter2.logoAsojacas.file.url} 
                     alt={queryFooter2.logoAsojacas.title}  
                  />
                  <img
                     loading="lazy"
                     className='f-final-content__box__imgs__img'
                     src={(queryFooter2.logoRedPactoGlobal) && queryFooter2.logoRedPactoGlobal.file.url} 
                     alt={queryFooter2.logoRedPactoGlobal.title}  
                  />
               </div>
               <article className='f-final-content__box__body' >
                  {queryFooter2.descripcion.json.content && documentToReactComponents(queryFooter2.descripcion.json)}
               </article>
            </div>
            <div className={`f-final-content__zone${ props.isSuperFooter ? ' f-final-content__zone--super' : ''}`} >
               <img loading="lazy" className='f-final-content__zone__logo' src={logoGray && logoGray.file.url}  alt={logoGray.title}  />
               <div className='f-final-content__zone__final'  >
                  <button 
                     className='f-final-content__zone__final__button icon-arrow-up'
                     onClick={() => scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                     }) }
                     aria-label="Ir arriba"
                  />
                  <a href="https://www.ssf.gov.co" rel="noreferrer follow" target="_black" >
                     <img
                        loading="lazy"
                        src={queryFooter2.lgoVigiladosVertical && queryFooter2.lgoVigiladosVertical.file.url} 
                        alt={queryFooter2.lgoVigiladosVertical.title}  
                     />
                  </a>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer;
