import { Box, Fade, Grow, Zoom } from '@material-ui/core';
import React from 'react';
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import styles from './headboardAniversario.module.scss';

export interface IHeadboardAniversarioProps {
    title: string;
    image: string;
    bgImage: string;
    youtubeId: string;
}

const HeadboardAniversario = ({
    title,
    image,
    bgImage,
    youtubeId,
}: IHeadboardAniversarioProps) => {
    return (
        <Box className={styles.o_headboard_aniversario}>
            <h1>{title}</h1>
            <Fade in timeout={1000} appear>
                <img
                    src={bgImage}
                    alt=""
                    className={styles.o_headboard_aniversario__bg}
                />
            </Fade>
            <Zoom in timeout={500}>
                <Fade in timeout={1000} appear>
                    <Box className={styles.o_headboard_aniversario__video}>
                        <YoutubePlayer youtubeId={youtubeId} />
                    </Box>
                </Fade>
            </Zoom>
            <Grow in timeout={1500} appear>
                <img
                    src={image}
                    alt=""
                    className={styles.o_headboard_aniversario__ribbon}
                />
            </Grow>
        </Box>
    );
};

export default HeadboardAniversario;
